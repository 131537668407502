import React from "react";
import { graphql, PageProps } from "gatsby";
import parse from "html-react-parser";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";

type DataProps = {
  post: {
    id: string;
    title: string;
    excerpt: string;
    content: string;
    date: string;
    featuredImage?: { node: { gatsbyImage: ImageDataLike; altText: string } };
  };
};

export default function BlogPost({ data: { post } }: PageProps<DataProps>) {
  const image =
    post.featuredImage?.node?.gatsbyImage &&
    getImage(post.featuredImage?.node?.gatsbyImage);

  return (
    <Layout>
      <Seo title={post.title} description={post.excerpt} />
      <article className="mb-24 h-entry entry">
        {post.title && post.title !== "Utan namn" ? (
          <>
            <h1 className="text-xl font-bold p-name">{post.title}</h1>
            <time className="dt-published" itemProp="datePublished">
              {post.date}
            </time>
          </>
        ) : (
          <h1 className="font-bold p-name">
            <time className="dt-published" itemProp="datePublished">
              {post.date}
            </time>
          </h1>
        )}

        {image && (
          <GatsbyImage
            image={image}
            className="block mt-2"
            loading="eager"
            alt={post.featuredImage?.node.altText ?? ""}
          />
        )}

        <div className="e-content entry-body">{parse(post.content)}</div>
      </article>
    </Layout>
  );
}

export const query = graphql`
  query BlogPost($id: String!) {
    post: wpPost(id: { eq: $id }) {
      id
      title
      excerpt
      content
      date(formatString: "dddd, Do MMM", locale: "sv-SE")
      featuredImage {
        node {
          gatsbyImage(width: 1200, placeholder: BLURRED)
          altText
        }
      }
    }
  }
`;
